// Design for News

$m: news;

.#{$m} {
    
    &--featured {
        display: block;
        background: $color_09;
        border-bottom: 2px solid #eaeaea;
        color: $color_08;
        @include font-size(14);
        padding: 20px;
        @include transition(0.2s);

        & + .news--featured {
            margin-top: 30px;
        }

        span {
            display: block;

            &.news__cats, &.news__date {
                display: inline-block;
            }
        }

        .#{$m}__cats {
            color: $gr_green;
            font-weight: $bold;
            text-transform: uppercase;
            border-left: 1px solid $color_08;
            line-height: 1;
            margin-left: 5px;

            span {
                display: inline-block;
                margin-left: 10px;
            }
        }

        .#{$m}__title {
            color: $color_01;
            font-family: $font_sec;
            font-weight: $bold;
            @include font-size(16);
            @include line-height(24);
            margin: 10px 0;
            @include transition(0.2s);
        }

        .#{$m}__thumb {
            width: 100%;
            height: auto;
        }

        &:hover {
            background: $gr_green;
            color: #FFF;

            .#{$m}__title {
                color: #FFF;
            }
        }
    }

    &--blog {
        padding: 0;

        .#{$m}__text {
            padding: 20px;
        }
    }
}

.category__list {
    @extend %ullist;
    text-align: center;
    margin-top: 30px;
    @include clearfix();

    @include min-screen($sm) {

    }

    li {
        display: block;
        vertical-align: top;
        padding: 0 30px;
        box-sizing: border-box;
        margin-bottom: 15px;

        @include min-screen($sm) {
            display: block;
            width: 50%;
            float: left;
        }

        @include min-screen($md) {
            width: auto;
            float: none;
            padding: 0 15px;
            display: inline-block;
        }

        a {
            display: block;
            @include border-radius(70px);
            padding: 10px 30px;
            border: 2px solid $gr_blue;
            @include font-size(14);
            text-transform: uppercase;
            font-weight: $bold;
            color: $gr_blue;
            @include transition(0.2s);

            &:hover {
                background: $gr_green;
                border: 2px solid $gr_green;
                color: #FFF;
            }
        }

        &.current-cat a {
            background: $gr_blue;
            border: 2px solid $gr_blue;
            color: #FFF;
        }

        &.cat-item-2 a:before {
            @extend %iconsetup;
            content: '\e805';
            margin-right: 10px;
        }
    }


    &--news li.cat-item-all a {
        background: $gr_blue;
        border: 2px solid $gr_blue;
        color: #FFF;

        &:hover {
            background: $gr_green;
            border: 2px solid $gr_green;
        }
    }
}

.news__container {
    padding: 20px 0 60px;

    .news--featured {
        background: transparent;
        padding: 20px 0;
        @include transition(0.2s);

        @include min-screen($md) {
            padding: 20px;
        }

        * {
            @include transition(0.2s);
        }

        &:hover {
            border-bottom: 2px solid $gr_green;

            * {
                color: $gr_green;
            }
        }

        .news__title {
            @include font-size(21);
        }
    }
}



// Archive News navigation
nav.posts-navigation {
    display: block;
    padding: 0 20px;
    margin-top: 30px;

    a {
        @include font-size(16);
        font-weight: $bold;

        &:hover {
            color: $gr_green;
        }
    }

    .nav-links {
        @include clearfix;
    }

    .nav-previous, .nav-next {
        display: block;
        text-align: center;

        @include min-screen($md) {
            display: inline-block;
        }
    }

    .nav-previous + .nav-next {
        @include min-screen($md) {
            margin-left: 20px;
        }
    }

    .nav-previous a:before {
        content: '\00ab';
        margin-right: 5px;
    }

    .nav-next a:after {
        content: '\00bb';
        margin-left: 5px;
    }

    .screen-reader-text {
        display: none;
    }
}
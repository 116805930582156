.wpcf7 {
    label {
        color: #979797;
        @include font-size(12);
        display: block;
        text-transform: uppercase;
        padding: 10px;
    }

    .wpcf7-form-control-wrap {
        display: block;

        input, textarea {
            background: #f6f6f6;
            outline: none;
            box-sizing: border-box;
            border: 2px solid transparent;
            @include box-shadow(none);
            padding: 12px 15px;
            font-family: $font-prim;
            @include font-size(14);
            color: $gr_green;
            max-height: 150px;
            resize: none;
            width: 100%;

            &:focus {
                border: 2px solid $gr_green;
                background: #FFF;
            }

            &.wpcf7-not-valid {
                border: 2px solid #d41e1e;
            }
        }
    }

    .wpcf7-not-valid-tip {
        display: block;
        @include font-size(12);
        font-weight: $bold;
        color: #d41e1e;
        margin-top: 5px;
    }

    .wpcf7-response-output {
        margin-top: 30px;
        color: $color_08;
        @include font-size(14);
    }

    .ajax-loader {
        width: 16px;
        height: 16px;
        display: inline-block;
        vertical-align: middle;
        margin-left: 10px;
    }

    .screen-reader-response {
        visibility: hidden;
        display: none;
    }
}
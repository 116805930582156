// Design for Navigation

$m: menu;

.#{$m} {
    position: fixed;
    top: 74px;
    left: 0;
    right: 0;
    bottom: 0;
    overflow: auto;
    height: calc(100vh - 74px);
    z-index: 1600;
    @include transition(0.5s);
    @include translateY(-150%);
    background: #FFF;

    &.is_open {
        @include translateY(0%);
    }

    @media screen and (min-width: $lg) {
        position: relative;
        top: auto;
        overflow: inherit;
        @include transition(0s);
        @include translateY(0);
        @include box-shadow(none);
        padding-top: 0;
        height: 100px;

        display: block;
        @include clearfix();
        text-align: right;

        ul {
            display: inline-block;
            float: none;
        }
    }
    
    &__list {
        @extend %ullist;
        @include clearfix;
        font-size: 0;
        text-align: right;
        overflow-y: auto;
        

        @include min-screen($lg) {
            float: left;
        }
    }

    &__item {
        position: relative;
        display: block;
        vertical-align: top;

        @media screen and (min-width: $lg) {
            display: inline-block;
        }

        a {
            display: block;
            text-align: center;
            color: $color_04;
            @include font-size(16);
            padding: 10px 20px;
            @include transition(0.2s);
            background: #FFF;
            text-transform: uppercase;
            font-weight: $bold;

            @media screen and (min-width: $lg) {
                font-weight: $light;
                padding: 38px 17px;
                text-transform: inherit;
            }

            &:after {
                @media screen and (min-width: $lg) {
                    content: '';
                    display: block;
                    bottom: 10px;
                    width: 16px;
                    height: 1px;
                    position: absolute;
                    left: 50%;
                    margin-left: -8px;
                    background: $color_01;
                    opacity: 0;
                    @include transition(0.2s);
                }
            }
        }

        &--hidden {
            @include screen($md, $xlg) {
                display: none;
            }
        }
    }



    &__item.current_page_item a, &__item.current-page-ancestor a {
        color: $color_01;
        font-weight: $bold;
    }

    &__item:hover a {
        color: $color_01;
    }

    &__item.current_page_item a:after, &__item:hover a:after, &__item.current-page-ancestor a:after {
        opacity: 1;
        bottom: 24px;
    }

    // lang switcher

    .language__list {
        @extend %ullist;
    }
    

    &__item--lang {
        width: 50%;
        display: inline-block;

        @media screen and (min-width: $lg) {
            width: auto;
        }

        &:hover backgrounddes {
            a: #eee;
        }
    }

    &__item--lang a {
        border-left: 1px solid #eeeeee;
        text-transform: uppercase;
        padding: 38px 15px;

        &:after {
            content: none;
        }
    }

    &__item--current-lang a {
        color: $color_01;
        font-weight: $bold;
    }

    &__item--search {
        display: none;

        @include min-screen($lg) {
            display: inline-block;
        }

        a {
            border-left: 1px solid #eeeeee;
            padding: 38px 15px;

            &:after {
                content: none;
            }

            .icon-search {
                padding: 10px;
                @include border-radius(50%);
                @include transition(0.2s);
            }

            &:hover .icon-search {
                background: $color_01;
                color: #FFF;
            }
        }
    }
}


// search popup
#search_form {
    position: relative;
    display: table;
    margin: 0 auto;
}


.search_popup {
    background: rgba($color_02,0.9);
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 9000;
    text-align: center;
    @include translateY(-100%);
    @include transition(0.3s);
    cursor: crosshair;

    &--visible {
        @include translateY(0%);
    }

    &__input {
        background: $color_02;
        margin-top: 100px;
        border: 2px solid #FFF;
        @include border-radius(50px);
        padding: 0 36px 0 20px;
        color: #FFF;
        width: 100%; //380
        max-width: 320px;
        height: 50px;
        outline: none;
        @include font-size(16);
        font-weight: $light;
        cursor: default;
        box-sizing: border-box;

        @include min-screen($lg) {
            padding: 0 46px 0 30px;
            height: 66px;
            @include font-size(18);
        }
    }

    &__submit {
        color: #FFF;
        @include font-size(16);
        position: absolute;
        top: 104px;
        right: 20px;
        padding: 10px;

        @include min-screen($lg) {
            top: 112px;
        }
    }
}




body.single-post li.current_page_parent a {
    color: $color_01;
    font-weight: $bold;

    &:after {
        opacity: 1;
        bottom: 24px;
    }
}

body.archive.category li.menu__item--news a {
    color: $color_01;
    font-weight: $bold;

    &:after {
        opacity: 1;
        bottom: 24px;
    }
}
// Design for services Navigation

$m: service_nav;

.#{$m} {

    ul {
        @extend %ullist;
        font-size: 0;
        text-align: center;
        padding: 30px 0;

        li {
            display: inline-block;

            a {
                @include font-size(14);
                color: $color_01;
                font-weight: $light;
                padding: 15px 15px;
                display: block;
                position: relative;
                @include transition(0.2s);

                @include min-screen($md) {
                    padding: 25px 15px;
                }

                &:after {
                    content: '';
                    display: block;
                    bottom: 0px;
                    width: 16px;
                    height: 1px;
                    position: absolute;
                    left: 50%;
                    margin-left: -8px;
                    background: $color_01;
                    opacity: 0;
                    @include transition(0.2s);
                }

                &:hover:after {
                    opacity: 1;
                    bottom: 10px;
                }
            }

            &.current_page_item a, &.current_page_ancestor a, &.current_page_parent a {
                font-weight: $bold;

                &:after {
                    opacity: 1;
                    bottom: 10px;
                }
            }
        }
    }
}
// Design for Buttons

$m: btn;

.#{$m} {
    display: inline-block;
    color: $gr_blue;
    text-transform: uppercase;
    font-family: $font_prim;
    @include font-size(14);
    font-weight: $bold;
    padding: 10px 30px;
    border: 2px solid $gr_blue;
    overflow: hidden;
    @include border-radius(70px);
    position: relative;
    z-index: 5;
    @include transitionfull(background 0.1s ease 0s);

    @include min-screen($md) {
        @include font-size(14);
        padding: 5px 40px;
    }

    span {
        position: relative;
        z-index: 5;
    }

    &:before {
        content: '';
        position: absolute;
        z-index: -1;
        background: $gr_blue;
        width: 100%;
        height: 100%;
        top: 100%;
        left: 0;
        opacity: 0.2;
        @include translateY(0%);
        @include transitionfull(all 0.4s ease-in-out 0.15s);
    }

    &:after {
        content: '';
        position: absolute;
        z-index: -1;
        background: $gr_blue;
        width: 100%;
        height: 100%;
        top: 100%;
        left: 0;
        @include translateY(0%);
        @include transitionfull(all 0.3s ease-in-out 0s);
    }

    &:hover {
        color: #FFF;
        background: $gr_blue;
        @include transitionfull(background 0.1s ease-in-out 0.3s);

        &:before {
            @include translateY(-200%);
            @include transitionfull(all 0.4s ease-in-out 0s);
        }

        &:after {
            @include translateY(-100%);
            @include transitionfull(all 0.3s ease-in-out 0.15s);
        }
    }

    &--submit {
        background: transparent;
        height: 45px;
        &:focus {
            outline: none;
        }

        &:hover {
            @include transitionfull(background 0.3s ease-in-out 0s);
        }
    }
}
// Design for Page Subfold

$m: sub_fold;

.#{$m} {
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    position: relative;
    text-align: right;
    width: 100%;
    padding: 25px 0;
    box-sizing: border-box;

    @include min-screen($md) {
        padding: 50px 0;
    }

    &:after {
        content: '';
        pointer-events: none;
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: 2;
        background: -moz-linear-gradient(left,  rgba(232,232,232,0.6) 0%, rgba(232,232,232,1) 75%, rgba(232,232,232,1) 100%);
        background: -webkit-linear-gradient(left,  rgba(232,232,232,0.6) 0%,rgba(232,232,232,1) 75%,rgba(232,232,232,1) 100%);
        background: linear-gradient(to right,  rgba(232,232,232,0.6) 0%,rgba(232,232,232,1) 75%,rgba(232,232,232,1) 100%);
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00e8e8e8', endColorstr='#e8e8e8',GradientType=1 );


        @include min-screen($lg) {
            background: -moz-linear-gradient(left,  rgba(232,232,232,0) 0%, rgba(232,232,232,1) 75%, rgba(232,232,232,1) 100%);
            background: -webkit-linear-gradient(left,  rgba(232,232,232,0) 0%,rgba(232,232,232,1) 75%,rgba(232,232,232,1) 100%);
            background: linear-gradient(to right,  rgba(232,232,232,0) 0%,rgba(232,232,232,1) 75%,rgba(232,232,232,1) 100%);
            filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00e8e8e8', endColorstr='#e8e8e8',GradientType=1 );
        }
    }

    &__title {
        color: $color_06;
        @include font-size(50);
        @include line-height(50);
        font-family: $font_sec;
        font-weight: $light;
        position: relative;
        z-index: 5;
        display: block;
        margin: 0;
        padding: 0 20px;

        @include min-screen($lg) {
            padding: 0;
        }
    }

    &__description {
        position: relative;
        color: $color_07;
        @include font-size(16);
        @include line-height(20);
        font-family: $font_sec;
        font-weight: $light;
        z-index: 5;
        padding: 0 20px;

        @include min-screen($lg) {
            @include font-size(21);
            @include line-height(25);
            padding: 0;
        }
    }
}
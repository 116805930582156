.search__results {
    margin: 30px 0;
    
    .container {
        font-size: 0;
    }


    .page-title {
        @include font-size(16);
    }

    .search-form {
        @include font-size(16);
        color: $color_04;


        label {
            span {
                display: none;
            }
            .search-field {
                background: #124163;
                border: 2px solid #FFF;
                -webkit-border-radius: 50px;
                -moz-border-radius: 50px;
                -ms-border-radius: 50px;
                -o-border-radius: 50px;
                border-radius: 50px;
                padding: 0 20px 0 20px;
                color: #FFF;
                width: 100%;
                max-width: 250px;
                height: 45px;
                outline: none;
                font-weight: 300;
                cursor: default;
                box-sizing: border-box;
            }
        }
        .search-submit {
            display: inline-block;
            background: transparent;
            outline: none;
            -webkit-border-radius: 70px;
            -moz-border-radius: 70px;
            -ms-border-radius: 70px;
            -o-border-radius: 70px;
            border-radius: 70px;
            padding: 10px 30px;
            border: 2px solid #2c699e;
            font-size: 14px;
            font-size: 1.4rem;
            text-transform: uppercase;
            font-weight: 700;
            color: #2c699e;
            -webkit-transition: .2s ease-in-out;
            -moz-transition: .2s ease-in-out;
            -o-transition: .2s ease-in-out;
            transition: .2s ease-in-out;
        }
    }
}

.search__item {
    display: inline-block;
    vertical-align: top;
    width: 100%;
    margin: 0;
    padding: 0;
    font-size: inherit;
    box-sizing: border-box;
    margin-bottom: 20px;

    // @include min-screen($md) {
    //     margin-top: 0;
    // }

    @include min-screen($md) {
        width: 50%;
    }
}
.product {
    margin-bottom: 60px;

    @include min-screen($md) {
        margin-bottom: 0;
    }

    &__head {
        display: block;
        @include clearfix();

        &--img {
            display: block;
            margin: 0 auto 20px;
            width: auto;
            max-height: 150px;

            @include min-screen($md) {
                width: 40%;
                height: auto;
                float: left;
                margin: 0 auto 0;
                max-height: inherit;
            }

            @include min-screen($lg) {
                width: 50%;
            }
        }

        &--info {
            width: 100%;
            box-sizing: border-box;
            @include clearfix();
            position: relative;
            min-height: 0px;

            @include min-screen($md) {
                width: 60%;
                float: left;
                padding-left: 20px;
            }

            @include min-screen($lg) {
                width: 50%;
            }
        }
        
        &--info > span {
            display: block;
        }

        &--type {
            color: $color_07;
            font-weight: $light;
            @include font-size(14);
            @include line-height(14);
            text-transform: uppercase;
        }

        &--title {
            color: $color_01;
            font-family: $font-sec;
            @include font-size(18);
            @include line-height(21);
            font-weight: $bold;
            margin-top: 10px;
        }

        &--price {
            color: $gr_green;
            @include font-size(24);
            @include line-height(24);
            font-weight: $bold;
            margin-top: 10px;
        }

        &--vat {
            @include font-size(14);
            @include line-height(14);
            font-weight: $normal;
            margin-left: 5px;
            display: inline-block;
            vertical-align: bottom;
        }

        &--buttons {
            margin-top: 20px;

            @include min-screen($lg) {
                position: absolute;
                bottom: 0;
                right: 0;
                left: 20px;
            }
        }

        &--btn {
            display: block;
            text-align: center;
            padding: 10px 0;
            box-sizing: border-box;
            border: 2px solid $color_01;
            @include border-radius(50px);
            text-transform: uppercase;
            font-weight: $bold;
            color: $color_01;
            @include font-size(14);
            @include line-height(14);

            &:hover {
                background: rgba($color_01, 0.1);
            }

            &.product--selected {
                background: $gr_green;
                border: 2px solid $gr_green;
                color: #FFF;
            }

            & + a {
                margin-top: 10px;
            }
        }

    }

    &__info p {
        color: $color_07;
        font-weight: $light;
        @include font-size(14);
        @include line-height(18);
        margin-top: 20px;
    }
}




.wpcf7 .wpcf7-form-control-wrap {
    textarea.products__input {
        // @include noselect();
        @include noselecttext();
        background: #fafafa;
        color: $color_07;
        @include font-size(16);
        @include line-height(24);
        font-weight: $bold;
        overflow: auto;
        max-height: 150px;
    }

    input.products__price,
    input.products__price__vat,
    input.products__price__total {
        text-align: right;
        @include noselect();
        border: 1px solid #d9d9d9;
        border-top: 0;
        background: transparent;
        color: $color_07;
        @include font-size(16);
        @include line-height(16);
    }

    input.products__price {
        border-top: 1px solid #d9d9d9;
    }

    input.products__price__vat {
        background: #f4f4f4;
        font-weight: $bold;
    }

    input.products__price__total {
        background: $gr_green;
        @include font-size(24);
        @include line-height(24);
        font-weight: $bold;
        color: #FFF;
    }

}


.wpcf7 .wpcf7-response-output {
    margin: 30px 15px 0;
    font-weight: $bold;
}
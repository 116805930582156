//**************************************************
// MAIN STYLES
//**************************************************
@import 'site_settings';



//**************************************************
// LIBS & INCLUDES (CORE)
//**************************************************
@import "lib/_normalize";
@import "lib/_slick_default";
// @import "lib/_magnific_popup_default";

//@import "includes/_flexbox";
@import "includes/_mixins";
@import "includes/_helpers";
@import "includes/_typography";
@import "includes/_layout";
@import "includes/_iconset";
// @import "includes/_utility";
// @import "modules/_styleguide";



//**************************************************
// MODULES
//**************************************************
//shared UI modules
// @import "modules/_cards";
// @import "modules/_buttons";
// @import "modules/_modal";
// @import "modules/_wysiwyg";
// @import "modules/_forms";
@import "modules/_menu";
@import "modules/_header";
@import "modules/_buttons";
@import "modules/_home_slider";
@import "modules/_footer";
@import "modules/_sub_fold";
@import "modules/_services";
@import "modules/_sidebar";
@import "modules/_news";
@import "modules/_pre_footer";
@import "modules/_nav_services";
@import "modules/_form";
@import "modules/_search";
@import "modules/_timeline";
@import "modules/_shop";


//specific modules
// @import "modules/_header";





//IE upgrade message
.browserupgrade {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	margin: 0;
	padding: 10px;
	color: #FFF;
	background: #e50278;
	text-align: center;
	z-index: 99999;
	font-size: 1.6em;

	a {
		color: #FFF;
		font-weight: $bold;
		text-decoration: underline;
	}
}
.cd-timeline-content {

    .cd-timeline-title-container {

        .cd-timeline-item-title {
            color: $gr_blue;
            font-family: $font_sec;
            font-weight: 700;
            @include font-size(16);
        }

        .timeline-date {
            @include font-size(14);
        }

    }

    .the-excerpt p {
        @include font-size(14);
    }

}
//---------------------------------------------------------------
// CORE SETTINGS
//---------------------------------------------------------------
html {
    font-size: $base-fontSize;
}

body {
    font-family: $font_prim;
    font-size: 100%;
    line-height: $base-lineHeight;
}

// html, body {
//     width: 100%;
//     height: 100%;
//     overflow:hidden; // to avoid scrollbars
// }

figure {
    position: relative;
    margin: 0;
    padding: 0;
    font-size: 0;
}


//---------------------------------------------------------------
// SITE WRAPPERS
//---------------------------------------------------------------
body {
    //padding: 130px 0 0 0;
    overflow-x: hidden;
    
    padding-top: 74px;

    @include min-screen($lg) {
        padding-top: 100px;
    }

    &.locked {
        @include max-screen($lg - 1) {
            overflow-y: hidden;

            .locked-iphone {
                // this stuff below is for shitty iPhones...
                height: 100%;
                width: 100%;
                position: fixed;
            }
        }
    }
}


.container {
    margin: 0 auto;
    max-width: $container-max-width;
}

body.home {
    @media screen and(min-height: 600px) {
        overflow-y: hidden;
    }
}


.reference__list a {
    display: inline-block;
    width: 33.33333%;
    text-align: center;

     @include min-screen($md) {
        width: 16.666666667%;
     }

    img {
        width: auto;
        max-width: 90%;
        height: auto;
    }
}

.google_map {
    width: 100%;
    height: 250px;

    @include min-screen($lg) {
        height: 500px;
    }

    img {
        max-width: inherit !important;
    }
}


// html #wpadminbar {
//     display: none;
// }
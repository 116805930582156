// Design for Header

$m: header;

.#{$m} {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 1500;
    background: #FFF;
    box-shadow: 0px 0px 20px rgba(0,0,0,0.1);
    padding: 20px;
    @include transition(0.3s);
    @include translateY(0%);
    backface-visibility: hidden;

    &.nav_up {
        @include translateY(-100%);
    }

    @media screen and (min-width: $lg) {
        padding: 0;
    }
    
    &__logo {
        display: inline-block;

        @media screen and (min-width: $lg) {
            margin-top: 35px;
        }
    }

    &__navigation {
        float: right;

        @media screen and (min-width: $lg) {
            width: 83.3333333%;
        }
    }

    &__toggle {
        text-indent: -9999px;
        color: transparent;
        position: relative;
        width: 40px;
        height: 30px;
        float: right;
        z-index: 1700;
        display: inline-block;
        vertical-align: middle;

        @include min-screen($lg) {
            display: none;
        }

        &:after, &:before, span {
            content: '';
            position: absolute;
            width: 30px;
            height: 4px;
            margin-left: 5px;
            background: $color_01;
            display: block;
            @include border-radius(5px);
            @include transition(0.3s);
        }

        span {
            top: 14px;
        }

        &:before {
            top: 6px;
        }

        &:after {
            top: 22px;
        }

        &.is_open {
            span {
                opacity: 0;
                margin-left: 15px;
            }

            &:before {
                @include rotate(-225deg);
                top: 14px;
            }

            &:after {
                @include rotate(225deg);
                top: 14px;
            }
        }
    }

    &__mobile_toggle {
        display: inline-block;
        @include font-size(16);
        vertical-align: middle;
        padding: 5px 7px;
        margin-right: 10px;

        @include min-screen($lg) {
            display: none;
        }
    }
}
//---------------------------------------------------------------
// GRID & LAYOUT SETTINGS
//---------------------------------------------------------------

// Grid specific:
$grid: 12;
$gutter: 40px;
$gutter_sm: 40px;
$gutter_md: 20px;
$gutter_lg: 20px;
$gutter_xlg: 20px;
$gutter_xxlg: 20px;


// Grid & Layout breakpoints:
$container-max-width: 1200px;

$xs: 479px;
$sm: 480px;
$md: 768px;
$lg: 1024px;
$xlg: 1200px;
$xxlg: 1600px;


// Širina ekrana na kojoj se preslaguju column_content i column_sidebar
$column_break: 768px;


//---------------------------------------------------------------
// FONTS
//---------------------------------------------------------------
$font_prim: 'Roboto', sans-serif;
$font_sec: 'Raleway', serif;


$base-fontSize: 62.5%;
$base-lineHeight: 1.5;


// Font weights
// $thin: 100;
// $ultra_light: 200;
$light: 300;
$normal: 400;
// $medium: 500;
// $semi_bold: 600;
$bold: 700;
// $extra_bold: 800;
// $ultra_bold: 900;




//---------------------------------------------------------------
// COLORS
//---------------------------------------------------------------
//font colors
$color_01: #2e689e; //plava
$color_02: #124163; //tamno plava
$color_03: #2c699e; //plava ikone
$color_04: #515151; //siva
$color_05: #add0ee; //svjetlo plava (footer heading)
$color_06: #2e689b; //subpage title
$color_07: #515151; //subpage desc
$color_08: #212121; //service text
$color_09: #f6f6f6; //news card bg
$gr_green: #2ca0a0; //gradient zelena
$gr_blue: #2c699e; //gradient plava




//Social networks colors
$color_facebook: #3b5998;
$color_twitter: #00b6f1;
$color_youtube: #b31217;
$color_instagram: #c32aa3;
$color_instagram: #c32aa3;
$color_pinterest: #cb2027;
$color_googleplus: #df4a32;
$color_googleplus: #df4a32;
$color_linkedin: #007bb6;
$color_vimeo: #45bbff;
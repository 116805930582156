// Design for Sidebar (Izdvajamo)

$m: sidebar;

.#{$m} {
    
    &__title {
        font-family: $font_sec;
        @include font-size(30);
        @include line-height(36);
        color: $color_01;
        margin: 40px 0 40px;
        padding-bottom: 40px;
        position: relative;

        @include min-screen($md) {
            margin: 0 0 40px;
        }

        &:after {
            content: '';
            position: absolute;
            display: block;
            width: 80px;
            height: 2px;
            background: $gr_green;
            bottom: 2px;
            left: 0;
        }
    }
}
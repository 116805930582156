// Design for Services list

$m: services;

.#{$m} {
    @include clearfix;

    &__item {
        display: block;
        float: left;
        height: 300px;
        width: 100%;
        @extend %gradient;
        text-align: center;
        position: relative;
        font-family: $font-sec;
        backface-visibility: hidden;
        -webkit-backface-visibility: hidden;
        z-index: 0;
        box-shadow: 0px 0px 0px rgba(0,0,0,0);
        @include transition(0.3s);


        &:last-child {
            display: none;

            @include min-screen($lg) {
                display: block;
            }
        }

        &:not(.services__item--quote):hover {
            z-index: 5;
            box-shadow: 0px 0px 50px rgba(0,0,0,0.25);

            @include transform("scale(1.05) scaleZ(1.0) translateZ(50px)");
            @include transform-origin("50% 50%");
        }

        @include min-screen($md) {
            width: 50%;
        }

        @include min-screen($lg) {
            width: 33.3333%;
        }

        a {
            color: #FFF;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            height: 100%;
            position: relative;
            display: block;
        }

        &--quote {
            color: #FFF;
            font-weight: $light;
            @include font-size(24);
            @include line-height(24);
            background: #113c60;

            @include min-screen($md) {
                @include font-size(36);
                @include line-height(36);
            }

            @include min-screen($lg) {
                @include font-size(42);
                @include line-height(42);
            }

            p {
                position: absolute;
                top: 50%;
                @include translateY(-50%);
                width: 100%;
                margin: 0;
                padding: 0 30px;
                box-sizing: border-box;
            }

            &:after {
                content: '';
                position: absolute;
                top: 20px;
                left: 20px;
                bottom: 20px;
                right: 20px;
                border: 1px solid rgba(255,255,255,0.5);
            }
        }
    }

    &__wrapper {
        position: absolute;
        top: 50%;
        @include translateY(-50%);
        width: 100%;
    }

    &__title {
        margin: 0;
        text-transform: uppercase;
        font-weight: $bold;
        @include font-size(24);
        @include line-height(24);

        @include min-screen($md) {
            @include font-size(30);
            @include line-height(30);            
        }
    }

    &__info {
        margin: 0;
        font-weight: $light;
        @include font-size(16);
        @include line-height(20);
        margin-top: 30px;
        padding: 0 20px;

        @include min-screen($md) {
            @include font-size(18);
            @include line-height(24);
            padding: 0 80px;
        }
    }

    // Service list Subpage (Category ISO norme)

    &_heading {
        @extend %gradient;
        color: #FFF;
        text-align: center;
        padding: 60px 0;
        position: relative;

        a {
            color: #FFF !important;
        }

        &__title {
            font-family: $font_sec;
            @include font-size(24);
            @include line-height(24);
            font-weight: $bold;
            text-transform: uppercase;
            margin: 0 0 20px;
            padding: 0 20px;

            @include min-screen($md) {
                @include font-size(30);
                @include line-height(30);
                padding: 0;
            }
        }

        &__text {
            font-weight: $light;
            margin: 0;
            padding: 0 20px;
            @include font-size(16);
            @include line-height(22);

            @include min-screen($md) {
                @include font-size(18);
                @include line-height(24);
            }
        }

        &__back_btn {
            position: absolute;
            background: url('../img/back-btn.png') no-repeat;
            background-size: 24px;
            width: 24px;
            height: 24px;
            top: 20px;
            left: 20px;
            text-indent: -9999px;
            color: transparent;
        }
    }


    // Services Subcategory list (cards)

    &_subcategory {
        margin-top: 45px;
        margin-bottom: 50px;
        position: relative;

        &__image {
            background-size: cover;
            background-position: center center;
            width: 100%;
            height: 120px;
            display: block;
            position: relative;
            overflow: hidden;
        }

        &__link {
            background: url('../img/open-btn-subcat.png') no-repeat;
            background-size: 120px;
            position: absolute;
            bottom: 0;
            right: 0;
            width: 120px;
            height: 120px;
            @include transition(0.3s);

            &:hover {
                @include transform("translate(10px,10px)");
            }
        }

        &__info {
            background: #FFF;
            box-shadow: inset 0px 0px 0px 1px rgba(0,0,0,0.15);
            margin-top: -1px;
            text-align: center;
            padding: 20px;

            @include min-screen($md) {
                height: 155px;
            }
        }

        &__title {
            margin: 0 0 20px;
            @include font-size(16);

            a {
                font-family: $font_sec;
                color: $color_01;
                font-weight: $bold;
            }
        }

        &__description {
            color: $color_07;
            font-weight: $light;
            @include font-size(14);
            @include line-height(18);
            margin: 0;
        }

        .container div[class*="gr-"] {
            margin-bottom: $gutter/2;
        }

        &:after {
            @extend %shadow;
        }
    }


    // Service single page
    
    &_single {
        margin-top: 30px;
        margin-bottom: 50px;
        position: relative;

        @include min-screen($md) {
            margin-top: 40px;
        }

        &:after {
            @extend %shadow;
            background-size: 100%;
        }

        &__title {
            font-family: $font_sec;
            @include font-size(24);
            @include line-height(24);
            color: $color_01;
            margin: 0 0 30px;
            padding-bottom: 30px;
            position: relative;

            @include min-screen($md) {
                @include font-size(30);
                @include line-height(36);
                margin: 0 0 40px;
                padding-bottom: 40px;
            }

            &:after {
                content: '';
                position: absolute;
                display: block;
                width: 80px;
                height: 2px;
                background: $gr_green;
                bottom: 2px;
                left: 0;
            }
        }

        &__content {
            img {
                width: 100%;
                height: auto;
            }
            h2 {
                @include font-size(24);
            }
            h3 {
                @include font-size(20);
                margin: 30px 0;
                padding-bottom: 20px;
            }
            h4 {
                @include font-size(18);
            }
            h5 {
                @include font-size(16);
                text-transform: uppercase;
            }
        }

        p, ul, ol {
            color: $color_08;
            @include font-size(16);
            @include line-height(24);
            margin: 15px 0;
        }
        ul {
            @extend %ullist;
            color: $color_01;
            font-weight: $bold;
            padding-left: 15px;

            li {
                position: relative;
                padding-left: 30px;
            }

            li + li {
                margin-top: 8px;
            }

            li:before {
                position: absolute;
                left: 0;
                top: 8px;
                content: '';
                display: block;
                width: 8px;
                height: 8px;
                background: $gr_green;
            }
        }
        ol {
            @extend %ullist;
            color: $color_01;
            font-weight: $bold;
            list-style-type: decimal;
            list-style-position: inside;
            padding-left: 15px;

            li {
                position: relative;
            }

            li + li {
                margin-top: 8px;
            }
        }
    }
}
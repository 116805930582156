// Design for Footer

$m: footer;

.#{$m} {
    position: relative;

    &--drawer {
        @media screen and(min-height: 600px) and (min-width: $lg) { //676
            position: absolute;
            width: 100%;
            z-index: 20;
            @include translateY(-80px);
            @include transitionfull(0.8s cubic-bezier(.51,.01,.55,1.5) 0.3s);
            @include animation(3s, 1s, drawer);
            backface-visibility: hidden;
            -webkit-backface-visibility: hidden;

            &:hover {
                @include translateY(-100%);
                @include transition(0.4s);
            }

            .footer__headings, .footer__content {
                padding: 25px 0;
            }
        }
    }

    &__headings {
        background: rgba(17,60,96,0.95);
        padding: 35px 0;

        i[class^="icon"] {
            color: $color_03;
            @include font-size(30);
            vertical-align: middle;
            margin-right: 20px;
        }

        h4 {
            color: $color_05;
            display: inline-block;
            margin: 0;
            @include font-size(16);
            text-transform: uppercase;
            font-weight: $light;
            vertical-align: middle;
        }
    }

    &__content {
        background: rgba(9, 44, 73, 0.95);
        padding-bottom: 30px;

        @include min-screen($md) {
            padding: 40px 0;
        }
    }

    &__news {
        display: block;
        padding: 15px 0;

        & + .footer__news {
            border-top: 1px solid rgba(255,255,255,0.15);
        }

        &__date {
            color: #467195;
            @include font-size(14);
            display: block;
        }

        &__heading {
            color: $color_05;
            @include font-size(14);
            @include line-height(18);
            margin: 0;
            font-weight: $bold;
            text-transform: uppercase;
        }

        &:hover &__heading {
            color: #FFF;
        }
    }

    &__paragraph {
        color: #467195;
        @include font-size(14);
    }

    &__services_list {
        @extend %ullist;

        li {
            display: block;
            @include font-size(14);
            font-weight: $bold;
            text-transform: uppercase;
            margin: 0;

            a {
                color: $color_05;
                padding: 12px 0 12px 30px;
                display: block;
                position: relative;

                &:before {
                    @extend %iconsetup;
                    content: '\f105';
                    position: absolute;
                    left: 0;
                    top: 10px;
                    display: inline-block;
                    vertical-align: middle;
                    color: #2a999b;
                    margin-right: 24px;
                    @include font-size(20);
                }

                &:hover {
                    color: #2a999b;
                }
            }
        }
    }

    &__bottom {
        background: #06233c;
    }

    &__bottom__nav {
        @extend %ullist;
        padding: 30px 0;
        text-align: center;

        @include min-screen($md) {
            text-align: left;
        }

        li {
            display: inline-block;
            vertical-align: top;

            & + li {
                margin-left: 20px;
            }

            a {
                text-transform: uppercase;
                color: $color_05;
                @include font-size(14);
                font-weight: $bold;

                &:hover {
                    color: #2a999b;
                }
            }
        }
    }

    &__copyright {
        text-align: center;
        color: $color_05;
        @include font-size(14);
        text-transform: uppercase;
        padding-top: 10px;

        @include min-screen($md) {
            text-align: right;
        }

        a {
            color: #2a999b;
            font-weight: $bold;

            .icon-sw {
                @include font-size(36);
                vertical-align: middle;
                margin-left: 15px;
            }
        }
    }


    &__socials {
        &__link {
            display: inline-block;
            width: 30px;
            height: 30px;
            background: url('../img/social-icons.png') no-repeat;
            background-size: 150px;
            background-position: 0 0;
            text-indent: -9999px;
            color: transparent;
            margin-right: 5px;

            &:hover {
                opacity: 0.7;
            }

            &--twitter {
                background-position: -30px 0;
            }

            &--linkedin {
                background-position: -60px 0;
            }

            &--youtube {
                background-position: -90px 0;
            }

            &--googleplus {
                background-position: -120px 0;
            }
        }
    }
}

@include keyframe(drawer) {
    0% {
        @include translateY(-80px);
        pointer-events: none;
    }
    30% {
        @include translateY(-250px);
    }
    60% {
        @include translateY(-250px);
    }
    100% {
        @include translateY(-80px);
        pointer-events: default;
    }
}
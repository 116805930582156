// Design for Home Slider

$m: home_slider;

.#{$m} {
    * {
        outline: none;
    }

    width: 100%;

    &__slide {
        @extend %gradient_homeslider;

        font-family: $font_sec;
        color: #FFF;
        position: relative;
        padding-bottom: 60px;

        @include min-screen($md) {
            min-height: 100vh;
            padding-bottom: 0;
            margin-top: -100px;
        }
    }

    &__content {
        z-index: 1;
        text-align: center;
        padding: 30px;

        @include min-screen($md) {
            text-align: left;
            position: absolute;
            top: 50%;
            left: 100px;
            right: 100px;
            padding: 0;
            @include translateY(-50%);
        }
    }

    &__heading {
        @include font-size(24);
        @include line-height(24);
        font-weight: $light;
        max-width: 750px;
        margin-bottom: 15px;

        @include min-screen($md) {
            @include font-size(60);
            @include line-height(60);
            margin: 0;
        }

        @media screen and (min-width: $xxlg) {
            @include font-size(72);
            @include line-height(72);
        }
    }

    &__text {
        @include font-size(21);
        @include line-height(25);
        font-weight: $light;
        margin: 40px 0;
        max-width: 750px;
        display: none;

        @include min-screen($md) {
            display: block;
        }
    }

    &__btn {
        color: #FFF;
        border: 2px solid #FFF;
        padding: 10px 30px;

        &:before, &:after {
            background: #FFF;
        }

        &:hover {
            color: $gr_green;
            background: #FFF;
        }

        @include min-screen($md) {
            padding: 15px 40px;
        }
    }

    &__image {
        height: 0;
        padding-bottom: 50%;
        background-size: cover;
        background-position: top right;

        @include min-screen($md) {
            position: absolute;
            top: 100px;
            right: 0;
            bottom: 0;
            left: 50%;
            width: 50%;
            z-index: 0;
            padding-bottom: 0;
            height: 100%;
            background-position: top left;
        }
    }

    &__dots {
        bottom: 30px;
        left: 0;
        width: 100%;
        text-align: center;
        position: absolute;
        @extend %ullist;

        @include min-screen($md) {
            bottom: 10px;
            left: 100px;
            text-align: left;
        }

        @media screen and (min-height: 600px) and (min-width: $md) {
            bottom: 110px;
        }

        @media screen and (min-height: 720px) and (min-width: $md) {
            bottom: 180px;
        }

        li {
            display: inline-block;
            margin-right: 12px;

            button {
                width: 20px;
                height: 20px;
                background: #FFF;
                opacity: 0.25;
                color: transparent;
                border: none;
                @include border-radius(50%);
                @include transition(0.3s);

                &:hover {
                    opacity: 0.5;
                }
            }

            &.slick-active button {
                opacity: 1;
            }
        }
    }
}
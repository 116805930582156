// ********************************
// includes/_mixins.scss
// ********************************

// @mixin font-size($sizeValue: 1.6)
// @mixin line-height($sizeValue: 2)
// @mixin clearfix()
// @mixin cf()
// @mixin border-box()
// @mixin border-radius($value: '5px')
// @mixin box-shadow($shadow)
// @mixin opacity($opacity)
// @mixin text-shadow($shadow)
// @mixin gradient($color1, $color2)
// @mixin transition($value)
// @mixin rotate($deg)
// @mixin screen($resMin, $resMax)
// @mixin max-screen($res)
// @mixin min-screen($res)
// @mixin screen-height($resMin, $resMax)
// @mixin max-screen-height($res)
// @mixin min-screen-height($res)
// @mixin respond-to($val, $query: min-width, $media: screen)
// @mixin ANIMATION

// @function em($target, $context: $base-fontSize)
// @function rem($size)


@mixin font-size($sizeValue: 1.6){
    font-size: ($sizeValue * 1) + px;
    font-size: ($sizeValue / 10) + rem;
}

@mixin line-height($sizeValue: 2){
    line-height: ($sizeValue * 1) + px;
    line-height: ($sizeValue / 10) + rem;
}

@mixin clearfix() {
    &:after {
        content: ".";
        display: block;
        height: 0;
        clear: both;
        visibility: hidden;
        zoom: 1
    }
}
//@include clearfix();

@mixin cf() {
    *zoom:1;

    &:before,
    &:after {
            content:"";
            display:table;
    }
    &:after {
            clear:both;
    }
}
//@include cf();

@mixin border-box() {
    -webkit-box-sizing: border-box;
       -moz-box-sizing: border-box;
            box-sizing: border-box;
}
//@include border-box();

@mixin border-radius($value: '5px') {
    -webkit-border-radius: $value;
       -moz-border-radius: $value;
        -ms-border-radius: $value;
         -o-border-radius: $value;
            border-radius: $value;
}
//@include border-radius(5px);

@mixin box-shadow($shadow) {
    -webkit-box-shadow: $shadow;
       -moz-box-shadow: $shadow;
            box-shadow: $shadow;
}
//@include box-shadow(0px 0px 5px #888888);
//@include box-shadow(0px 0px 5px rgba(0,0,0,0.7));

@mixin opacity($opacity) {
    -webkit-opacity:$opacity;
       -moz-opacity:$opacity;
        -ms-opacity:$opacity;
         -o-opacity:$opacity;
            opacity:$opacity;
}
//@include opacity(0.8);

@mixin text-shadow($shadow) {
    -webkit-text-shadow: $shadow;
       -moz-text-shadow: $shadow;
            text-shadow: $shadow;
}
//@include text-shadow(2px 2px #ff0000);

@mixin gradient($color1, $color2){
    background: $color1;
    background: -webkit-linear-gradient($color1, $color2);
    background:    -moz-linear-gradient($color1, $color2);
    background:     -ms-linear-gradient($color1, $color2);
    background:      -o-linear-gradient($color1, $color2);
    background:         linear-gradient($color1, $color2);
}
//@include gradient(#ff0000, #0000ff);
//za više boje koristiti generator http://www.colorzilla.com/gradient-editor/

@mixin transition($value) {
    -webkit-transition: $value ease-in-out;
       -moz-transition: $value ease-in-out;
         -o-transition: $value ease-in-out;
            transition: $value ease-in-out;
}

@mixin transitionfull($value) {
    -webkit-transition: $value;
       -moz-transition: $value;
         -o-transition: $value;
            transition: $value;
}
//@include transition(2s);

@mixin rotate($deg) {
    -webkit-transform: rotate($deg);
       -moz-transform: rotate($deg);
         -o-transform: rotate($deg);
        -ms-transform: rotate($deg);
            transform: rotate($deg);
}
//@include rotate(7deg);


@mixin transform($value) {
    -webkit-transform: #{$value};
       -moz-transform: #{$value};
         -o-transform: #{$value};
        -ms-transform: #{$value};
            transform: #{$value};
}

@mixin transform-origin($value) {
    -webkit-transform-origin: #{$value};
       -moz-transform-origin: #{$value};
         -o-transform-origin: #{$value};
        -ms-transform-origin: #{$value};
            transform-origin: #{$value};
}

@mixin perspective($value) {
    -webkit-perspective: #{$value};
       -moz-perspective: #{$value};
            perspective: #{$value};
}

@mixin translateX($value) {
    -webkit-transform: translateX($value);
       -moz-transform: translateX($value);
         -o-transform: translateX($value);
        -ms-transform: translateX($value);
            transform: translateX($value);
}
//@include translateX(200px);

@mixin translateY($value) {
    -webkit-transform: translateY($value);
       -moz-transform: translateY($value);
         -o-transform: translateY($value);
        -ms-transform: translateY($value);
            transform: translateY($value);
}
//@include translateY(200px);

@mixin scale($value) {
    -webkit-transform: scale($value);
     -moz-transform: scale($value);
       -o-transform: scale($value);
      -ms-transform: scale($value);
          transform: scale($value);
}
//@include scale(1.1);

@mixin skew($value1, $value2) {
        -ms-transform: skew($value1, $value2);
    -webkit-transform: skew($value1, $value2);
            transform: skew($value1, $value2);
}
//@include skew(0deg, -2deg);

// Photoshop-like letter spacing
@mixin letter-spacing($sizeValue){
  letter-spacing: ($sizeValue / 1000) + em;
}
//@include letter-spacing(50);


@mixin bg-cover($image){
  background: url($image) 0 0 no-repeat;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}
//@include bg-cover("../img/bg.jpg");


@mixin vertical-align($position: relative) {
  position: $position;
  top: 50%;
  -webkit-transform: perspective(1px) translateY(-50%);
  -ms-transform: perspective(1px) translateY(-50%);
  transform: perspective(1px) translateY(-50%);
}
//@include vertical-align();

// Photoshop-like letter spacing
@mixin letter-spacing($sizeValue){
    letter-spacing: ($sizeValue / 1000) + em;
}
//@include letter-spacing(50);


// Custom Mixins for Media Queries
// --------------------------------------------------------------------------------------------------------------------
//   @min-screen(width)                      // shortcut for @media screen and (min-width ...)
//   @max-screen(width)                      // shortcut for @media screen and (max-width ...)
//   @screen(min-width, max-width)           // shortcut for @media screen and (min-width ...) and (max-width ...)
//   ---
//   @min-screen-height(height)              // shortcut for @media screen and (min-height ...)
//   @max-screen-height(height)              // shortcut for @media screen and (max-height ...)
//   @screen-height(min-height, max-height)  // shortcut for @media screen and (min-height ...) and (max-height ...)
// --------------------------------------------------------------------------------------------------------------------
@mixin screen($resMin: '0px', $resMax: '9999px') {
    @media screen and (min-width: $resMin) and (max-width: $resMax) { @content; }
}

@mixin max-screen($res) {
    @media screen and (max-width: $res) { @content; }
}

@mixin min-screen($res) {
    @media screen and (min-width: $res) { @content; }
}

@mixin screen-height($resMin: '0px', $resMax: '9999px') {
    @media screen and (min-height: $resMin) and (max-height: $resMax) { @content; }
}


@mixin max-screen-height($res) {
    @media screen and (max-height: $res) { @content; }
}

@mixin min-screen-height($res) {
    @media screen and (min-height: $res) { @content; }
}


// ----- Respond-to ----- //
@mixin respond-to($val, $query: min-width, $media: screen) {
    @media #{$media} and ($query: $val) {
        @content;
    }
}


@mixin aspect-ratio($width, $height) {

    position: relative;

    &:before {
        display: block;
        content: "";
        width: 100%;
        padding-top: ($height / $width) * 100%;
    }

    > .ar_content {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
    }
}


@mixin animation($duration, $delay, $animation) {
    -webkit-animation-delay: $delay;
    -webkit-animation-duration: $duration;
    -webkit-animation-name: $animation;

    -moz-animation-delay: $delay;
    -moz-animation-duration: $duration;
    -moz-animation-name: $animation;

    -o-animation-delay: $delay;
    -o-animation-duration: $duration;
    -o-animation-name: $animation;

    animation-delay: $delay;
    animation-duration: $duration;
    animation-name: $animation;
}

@mixin keyframe($animation_name) {
    @-webkit-keyframes #{$animation_name} {
        @content;
    }

    @-moz-keyframes #{$animation_name} {
        @content;
    }

    @-o-keyframes #{$animation_name} {
        @content;
    }

    @keyframes #{$animation_name} {
        @content;
    }
}

@mixin noselect {
  pointer-events: none;
}

@mixin noselecttext {
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}
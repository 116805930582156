//---------------------------------------------------------------
// Iconset (font icons)
//---------------------------------------------------------------
//@import "https://i.icomoon.io/public/temp/f013016493/BestOfIsland/style.css";

//---------------------------------------------------------------
// Icon template
//---------------------------------------------------------------
%iconsetup {
    font-family: 'texel' !important;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

@font-face {
    font-family: 'texel';
    src:    url('../fonts/texel.eot?20161112');
    src:    url('../fonts/texel.eot?20161112#iefix') format('embedded-opentype'),
        url('../fonts/texel.ttf?20161112') format('truetype'),
        url('../fonts/texel.woff?20161112') format('woff'),
        url('../fonts/texel.svg?20161112#texel') format('svg');
    font-weight: normal;
    font-style: normal;
}

[class^="icon-"], [class*=" icon-"] {
    @extend %iconsetup;
}

.icon-novosti:before { content: '\e800'; } /* '' */
.icon-search:before { content: '\e801'; } /* '' */
.icon-sw:before { content: '\e802'; } /* '' */
.icon-usluge:before { content: '\e803'; } /* '' */
.icon-share:before { content: '\e804'; } /* '' */
.icon-star:before { content: '\e805'; } /* '' */
.icon-right-open:before { content: '\e806'; } /* '' */
.icon-left-open:before { content: '\e807'; } /* '' */
.icon-angle-left:before { content: '\f104'; } /* '' */
.icon-angle-right:before { content: '\f105'; } /* '' */
// Design for Pre Footer

$m: pre_footer;

.#{$m} {
    color: $gr_blue;
    font-family: $font_sec;
    @include font-size(30);
    @include line-height(30);
    font-weight: $light;
    padding: 100px 0;

    @include min-screen($md) {
        @include font-size(42);
        @include line-height(42);
    }

    &__text {
        padding: 0;
        margin: 0;
        margin-bottom: 40px;
    }
}